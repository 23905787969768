import './App.css';
import { useEffect, useState } from 'react';
import { IoInformationCircleOutline } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import { IoClose } from 'react-icons/io5';
import { IoMdShare } from "react-icons/io";
import { MdOutlineBlock } from "react-icons/md";
import { FaFilter } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { FaCalculator } from "react-icons/fa";

function App() {

  const [allData, setAllData] = useState([]);

  const [carData, setCarData] = useState([]);

  const [houseData, setHouseData] = useState([]);

  const [selectedData, setSelectedData] = useState([]);

  const [phoneNumber, setPhoneNumber] = useState('5511992867086');

  const [showInfoPopup, setShowInfoPopup] = useState(false);

  const [selectedCard, setSelectedCard] = useState({});

  const [filters, setFilters] = useState([]);

  const [showFilterPopup, setShowFilterPopup] = useState(false);

  const [minValue, setMinValue] = useState(null);

  const [maxValue, setMaxValue] = useState(null);

  const [minInstallment, setMinInstallment] = useState(null);

  const [maxInstallment, setMaxInstallment] = useState(null);

  const [showFirstScreen, setShowFirstScreen] = useState(true);

  const [type, setType] = useState('');

  const [searchTerm, setSearchTerm] = useState('');

  const [showSearch, setShowSearch] = useState(false);

  const [selectedCards, setSelectedCards] = useState([]);

  const [showSumPopup, setShowSumPopup] = useState(false);

  function summarizeInstallments(data) {
    let installmentArray = [];
    data.forEach(item => {
      let n = 1;
      while (n <= item.term) {
        if (!installmentArray[n]) {
          installmentArray[n] = 0;
        }
        installmentArray[n] += item.installment;
        n++;
      }
    })
    let totalArray = [];
    let i = 1;
    let last = 1;
    while (i < installmentArray.length) {
      if ((installmentArray[i] !== installmentArray[i + 1]) && installmentArray[i]) {
        totalArray.push({ rangeStart: last, rangeEnd: i, value: installmentArray[i] });
        last = i + 1;
      }
      i++;
    }
    return totalArray;
  }

  useEffect(() => {
    const apiUrl = 'https://app.dwconsorcios.com.br/api/v1/contemplados';

    const headers = {
      "Accept": "application/json"
      // Add other necessary headers here
    };

    const xhr = new XMLHttpRequest();
    xhr.open("GET", apiUrl);

    // Set headers
    for (const header in headers) {
      xhr.setRequestHeader(header, headers[header]);
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const data = JSON.parse(xhr.responseText);
        setAllData(data);
        setCarData(data.filter(item => item.category === 'veículo'));
        setHouseData(data.filter(item => item.category === 'imóvel'));
      } else {
        console.error('Error fetching data:', xhr.status, xhr.statusText);
      }
    };

    xhr.onerror = () => {
      console.error('Request failed');
    };

    xhr.send();
  }, []);

  function deselectAll() {
    setSelectedCards([]);
    selectedData.forEach(item => {
      item.selected = false;
    })
  }

  function selectAll() {
    setSelectedCards([...selectedData]);
    selectedData.forEach(item => {
      item.selected = true;
    })
  }

  useEffect(() => {
    let filteredData = type === 'veículo' ? carData : type === 'imóvel' ? houseData : allData;
    let filtersArray = [...filters];


    if (type === 'veículo' || type === 'imóvel') {
      filtersArray.push('type');
    } else {
      filtersArray = filtersArray.filter(item => item !== 'type');
    }

    if (minValue) {
      filteredData = filteredData.filter(item => item.value >= minValue);
      filtersArray.push('minValue');
    } else {
      filtersArray = filtersArray.filter(item => item !== 'minValue');
    }

    if (maxValue) {
      filteredData = filteredData.filter(item => item.value <= maxValue);
      filtersArray.push('maxValue');
    } else {
      filtersArray = filtersArray.filter(item => item !== 'maxValue');
    }

    if (minInstallment) {
      filteredData = filteredData.filter(item => item.installment >= minInstallment);
      filtersArray.push('minInstallment');
    } else {
      filtersArray = filtersArray.filter(item => item !== 'minInstallment');
    }

    if (maxInstallment) {
      filteredData = filteredData.filter(item => item.installment <= maxInstallment);
      filtersArray.push('maxInstallment');
    } else {
      filtersArray = filtersArray.filter(item => item !== 'maxInstallment');
    }

    setFilters([...filtersArray]);

    if (searchTerm) {
      //Use the filter function to search for the term in the data
      //Search in the id, administrator name, category
      //Ignore case, accents and special characters
      filteredData = filteredData.filter(item => {
        return ('#' + item.id.toString()).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase())
          || item.administrator.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase())
          || item.category.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase());
      });
    }

    setSelectedData([...filteredData]);
  }, [type, minValue, maxValue, minInstallment, maxInstallment, allData, carData, houseData, searchTerm]);

  useEffect(() => {
    setSelectedCards([]);
    deselectAll();
  }, [type]);

  return (
    <div className="App">
      <header>
        <nav>
          <div className='logo'>
            <a href='https://csiinvestimento.com.br'>
              <img src='https://csiinvestimento.com.br/wp-content/uploads/2024/02/Logo-1.png' />
            </a>
          </div>
          <div>
            <span onClick={() => {
              //Navigate to https://csiinvestimento.com.br
              window.location.href = 'https://csiinvestimento.com.br';
            }}>
              Início
            </span>
          </div>
          <div>
            <span onClick={() => { setType('imóvel'); setShowFirstScreen(false); }}>
              Imóvel
            </span>
          </div>
          <div>
            <span onClick={() => { setType('veículo'); setShowFirstScreen(false); }}>
              Veículo
            </span>
          </div>
          <div>
            <span onClick={() => { setType(''); setShowFirstScreen(false); }}>
              Todos
            </span>
          </div>
        </nav>
      </header>
      <div className='pagefiller'>
        <h1>Cartas contempladas</h1>
        {!showFirstScreen &&
          <div className='sumbutton' onClick={() => {
            if (selectedCards.length < 2) {
              alert('Selecione ao menos 2 cartas para somar');
            } else if (!(selectedCards.every((card, index, array) => card.category === array[0].category))) {//Check if all selected cards are from the same category
              alert('Apenas cartas do mesmo segmento podem ser somadas');
            } else if (selectedCards.some(card => card.status === 'reservada')) {//Check if all the selected cards are available
              alert('Apenas cartas disponíveis podem ser somadas');
            } else {
              //Scroll to top and disable scrolling for body
              window.scrollTo(0, 0);
              document.body.style.overflow = 'hidden';
              setShowSumPopup(true);
            }
          }}>
            <FaCalculator />
            Somar
          </div>
        }
        {showFirstScreen &&
          <>
            <div className='textcontainer'>
              <p>As cotas de consórcio contempladas são uma excelente opção para quem necessita do crédito para aquisição de um bem imóvel ou automóvel de forma imediata.</p>
              <h3><span>Escolha um segmento:</span></h3>
            </div>
            <div className='buttoncontainer'>
              <div className='button' onClick={() => { setType('imóvel'); setShowFirstScreen(false); }}>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 177.000000"
                  preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,177.000000) scale(0.100000,-0.100000)"
                    fill="currentColor" stroke="none">
                    <path d="M1720 1283 l0 -76 -22 21 c-13 11 -35 24 -51 27 -15 4 -30 12 -34 18
-3 5 -2 8 3 5 5 -3 29 -2 54 3 41 7 38 7 -25 6 -39 -1 -84 4 -102 12 -31 13
-33 12 -79 -30 -25 -24 -51 -43 -56 -41 -10 3 -178 -85 -178 -93 0 -2 30 -51
66 -109 53 -86 70 -106 89 -106 13 0 26 -5 28 -10 3 -6 4 -5 3 2 -1 7 -13 15
-25 18 -16 3 -42 36 -83 103 -33 54 -58 100 -56 101 14 11 138 76 144 76 5 0
-10 -19 -31 -42 -22 -24 -6 -10 36 30 42 39 80 72 84 72 4 0 -29 -40 -74 -89
-79 -86 -90 -101 -73 -101 15 0 84 69 78 78 -3 6 16 29 42 53 26 24 49 54 52
67 5 22 7 21 64 -25 33 -26 58 -51 55 -55 -7 -10 40 -55 75 -71 17 -8 47 -18
66 -21 29 -6 33 -5 20 6 -8 7 -16 20 -18 28 -2 8 -12 25 -23 36 -16 17 -17 23
-7 27 10 4 10 6 1 6 -9 1 -13 22 -13 71 0 68 1 70 25 70 24 0 25 -3 26 -57 1
-32 0 -66 -1 -77 -3 -31 17 -8 33 37 8 25 11 50 6 62 -6 16 -8 13 -8 -15 -2
-61 -21 -72 -21 -12 0 30 4 52 11 52 5 0 7 5 4 10 -3 6 -24 10 -46 10 l-39 0
0 -77z m-3 -97 c42 -39 50 -52 38 -58 -16 -10 -83 20 -104 46 -20 24 -5 19 41
-14 22 -16 46 -30 52 -30 6 0 2 6 -9 12 -24 14 -155 119 -155 124 0 2 19 -4
43 -13 23 -9 66 -39 94 -67z m-307 -29 c0 -2 -17 -19 -37 -38 l-38 -34 34 38
c33 34 41 42 41 34z"/>
                    <path d="M1843 1269 c9 -6 15 -14 13 -18 -11 -16 263 -257 300 -264 14 -2 22
-1 17 2 -4 4 -53 49 -109 99 -94 86 -135 115 -103 75 13 -16 12 -17 -4 -4 -9
8 -15 16 -12 19 3 3 0 11 -6 19 -24 29 8 9 139 -86 75 -54 170 -120 209 -146
40 -26 70 -52 66 -58 -4 -6 0 -8 10 -4 10 3 17 2 17 -2 0 -5 8 -12 18 -15 36
-14 -36 44 -189 151 -228 161 -354 243 -370 243 -10 -1 -9 -4 4 -11z m61 -49
c4 0 4 6 0 13 -5 8 -2 7 9 -2 10 -8 15 -16 12 -19 -3 -3 0 -11 6 -19 21 -24 1
-13 -33 20 -19 17 -27 26 -18 20 9 -7 20 -13 24 -13z m126 -117 l45 -47 -50
44 c-27 24 -52 45 -54 47 -2 2 0 3 5 3 6 0 30 -21 54 -47z"/>
                    <path d="M1465 1205 c-47 -42 -56 -65 -11 -28 12 10 3 -3 -20 -27 l-41 -45 -1
-85 c0 -71 1 -78 8 -45 5 22 9 60 9 86 1 38 6 50 36 79 20 19 33 40 31 47 -3
7 6 21 21 33 l25 20 32 -27 c122 -103 119 -97 119 -165 0 -35 -3 -75 -7 -90
-5 -15 -5 -36 -1 -45 3 -10 6 -68 6 -130 0 -96 3 -116 19 -135 19 -23 19 -22
20 118 l0 141 198 2 197 2 2 -167 c1 -91 4 -169 7 -172 3 -3 6 72 6 166 l0
172 48 0 c26 0 62 -4 80 -9 18 -6 30 -6 27 -1 -3 4 1 11 7 13 7 3 2 5 -12 4
-74 -2 -603 4 -599 7 2 2 137 7 299 9 181 4 289 2 280 -3 -13 -8 -13 -10 0
-10 42 0 36 17 -12 35 -47 17 -74 18 -309 12 l-259 -6 37 54 c20 31 34 58 31
61 -3 2 -15 -10 -27 -28 l-21 -33 0 43 c0 45 4 48 45 32 15 -6 24 -6 20 -1 -3
5 -19 12 -36 16 -16 4 -66 38 -110 76 -44 38 -83 69 -87 69 -4 -1 -29 -21 -57
-45z m475 -255 c-14 -4 -81 -8 -150 -7 l-125 1 125 6 c69 3 136 6 150 7 l25 1
-25 -8z m160 -1 c-61 -4 -109 -3 -114 2 -6 5 36 8 105 8 l114 -2 -105 -8z
m-403 -236 c-2 -37 -3 -9 -3 62 0 72 1 102 3 68 2 -34 2 -93 0 -130z"/>
                    <path d="M1597 1209 c12 -12 25 -20 28 -17 3 3 -7 13 -22 23 l-28 17 22 -23z" />
                    <path d="M1087 1154 c-26 -14 -45 -29 -42 -34 3 -5 0 -8 -7 -5 -17 7 -124 -51
-120 -65 1 -6 -2 -9 -7 -5 -6 3 -34 -6 -63 -21 -70 -35 -228 -125 -228 -131 0
-2 9 1 19 7 26 13 34 13 25 -2 -4 -7 -3 -8 5 -4 6 4 9 12 5 17 -3 6 0 9 8 7
22 -5 259 122 252 134 -4 7 -2 8 4 4 13 -8 155 61 148 72 -2 4 6 8 17 8 18 1
33 -19 83 -110 34 -61 66 -110 70 -109 5 1 15 -4 23 -10 8 -7 3 8 -12 33 -14
25 -46 81 -70 125 -24 44 -49 80 -55 80 -6 0 -14 4 -19 9 -7 8 4 7 77 -9 15
-3 10 2 -15 14 -50 24 -41 25 -98 -5z m118 -124 c9 -16 13 -30 11 -30 -3 0
-12 14 -21 30 -9 17 -13 30 -11 30 3 0 12 -13 21 -30z m40 -70 c9 -16 13 -30
11 -30 -3 0 -12 14 -21 30 -9 17 -13 30 -11 30 3 0 12 -13 21 -30z"/>
                    <path d="M1461 1068 l2 -43 6 35 c5 28 11 36 31 38 20 2 22 1 10 -7 -13 -9
-13 -11 3 -11 12 0 17 -9 18 -32 0 -28 2 -30 9 -13 8 18 9 17 9 -7 1 -24 -3
-28 -28 -28 -27 0 -30 4 -34 38 l-4 37 -1 -37 c-2 -33 -5 -38 -27 -38 -23 0
-25 -4 -25 -42 0 -66 5 -69 106 -66 l89 3 2 60 c1 43 -1 53 -7 35 -4 -14 -8
-40 -9 -57 l-1 -33 -80 0 -80 0 5 28 c4 22 4 24 -4 7 -8 -16 -10 -13 -10 20
-1 29 2 36 9 25 6 -10 10 -11 10 -2 0 29 19 9 23 -25 l4 -38 1 37 2 37 49 -1
50 0 4 -36 4 -37 1 38 c2 31 -2 40 -18 44 -14 4 -20 14 -20 34 0 16 -4 29 -9
29 -5 0 -11 9 -14 20 -3 11 -1 20 4 20 5 0 9 -5 9 -10 0 -7 6 -7 19 0 29 15
29 14 34 -32 l4 -43 1 48 2 47 -75 0 -75 0 1 -42z"/>
                    <path d="M1005 1030 c-23 -9 -65 -55 -65 -71 0 -8 13 -10 37 -6 37 6 93 50 93
74 0 14 -34 16 -65 3z m38 -12 c-12 -6 -23 -19 -26 -29 -3 -11 -10 -19 -17
-19 -6 0 -8 6 -4 13 4 6 -3 3 -16 -8 -16 -14 -22 -16 -18 -5 6 19 66 60 87 59
12 0 10 -3 -6 -11z"/>
                    <path d="M1516 955 c3 -16 7 -32 9 -33 8 -9 25 11 25 29 0 21 -20 27 -21 7 0
-7 -5 -4 -10 7 -7 16 -8 14 -3 -10z"/>
                    <path d="M1562 950 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z" />
                    <path d="M2308 923 c10 -4 10 -8 -3 -17 -13 -9 -12 -11 9 -11 20 0 22 3 15 18
-5 9 -15 17 -22 16 -8 0 -8 -2 1 -6z"/>
                    <path d="M597 883 c-4 -3 -7 -15 -7 -25 0 -13 7 -18 28 -17 24 2 25 2 4 6 -18
3 -23 10 -19 24 5 20 4 23 -6 12z"/>
                    <path d="M855 882 l-211 -2 4 -35 c2 -19 8 -35 13 -35 5 0 10 -44 11 -97 l2
-98 3 90 c5 131 18 124 16 -8 -2 -132 12 -116 19 21 4 83 2 94 -14 103 -10 5
-18 18 -18 28 0 18 12 19 237 20 130 1 235 -1 232 -3 -3 -3 -87 -6 -188 -6
-101 -1 -180 -4 -177 -7 3 -4 92 -5 197 -4 233 3 221 10 230 -149 l5 -105 4
115 4 115 6 -80 6 -80 2 76 c2 58 -2 80 -13 89 -21 17 -19 39 3 34 9 -3 51 -8
92 -12 l75 -7 -1 -30 c-1 -16 -3 -48 -4 -70 -1 -22 -1 -58 0 -80 l3 -40 8 45
c4 25 8 76 8 114 1 66 2 68 24 62 12 -3 63 -6 112 -6 l91 0 -2 -105 c-2 -57 1
-107 6 -110 5 -3 8 46 7 112 l-2 118 -148 1 c-81 1 -151 4 -154 8 -3 3 18 6
47 6 79 0 55 10 -36 15 -50 2 -70 1 -54 -5 22 -7 23 -8 4 -9 -11 -1 -24 2 -27
6 -8 7 -131 9 -422 5z m358 -50 c7 -9 6 -11 -7 -6 -9 3 -16 16 -15 28 0 14 2
16 6 6 3 -8 10 -21 16 -28z"/>
                    <path d="M2292 750 l2 -125 3 118 4 117 41 0 c53 0 42 12 -12 14 l-40 1 2
-125z"/>
                    <path d="M723 843 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
                    <path d="M1252 715 c-1 -71 1 -125 6 -120 4 6 5 64 1 130 l-5 120 -2 -130z" />
                    <path d="M1780 767 l0 -73 120 -2 c101 -1 118 1 114 13 -4 8 -17 15 -30 15
-22 0 -24 4 -24 45 0 38 3 45 20 45 11 0 20 -7 20 -16 0 -8 5 -12 10 -9 6 3
10 -4 10 -17 0 -13 5 -30 10 -38 7 -10 9 3 7 45 l-2 60 -128 3 -127 3 0 -74z
m75 53 c-4 -6 10 -10 34 -10 l40 0 7 -47 c4 -27 12 -52 18 -56 7 -6 4 -7 -7
-3 -10 3 -34 6 -52 6 -33 0 -34 1 -38 48 l-3 47 -2 -52 c-2 -46 -4 -51 -22
-46 -17 4 -20 14 -20 54 0 57 17 66 23 12 l4 -38 1 43 c2 35 -1 42 -17 42 -11
0 -22 -6 -24 -12 -3 -8 -6 -6 -6 5 -1 13 7 17 35 17 21 0 33 -4 29 -10z m165
2 c0 -4 -16 -6 -35 -4 -29 3 -35 1 -36 -14 -1 -16 -2 -17 -6 -2 -2 9 -12 19
-21 21 -9 3 9 5 41 6 31 0 57 -3 57 -7z"/>
                    <path d="M1448 719 l-2 -114 -6 110 -6 110 -2 -107 c-1 -74 -6 -108 -14 -111
-7 -3 -3 -6 9 -6 12 -1 26 7 31 17 8 17 5 202 -4 211 -2 2 -5 -47 -6 -110z"/>
                    <path d="M2194 818 c-3 -4 4 -8 15 -8 20 0 21 -6 21 -95 l0 -95 -26 0 -27 0 7
90 c5 64 4 90 -4 90 -7 0 -10 -30 -8 -92 l3 -93 45 -2 c25 -1 39 0 33 3 -9 4
-13 34 -13 100 0 103 17 129 23 37 4 -57 4 -57 3 7 l-1 65 -33 1 c-18 0 -35
-3 -38 -8z"/>
                    <path d="M1497 813 c-2 -5 -3 -36 -2 -70 1 -35 -1 -63 -4 -63 -4 0 -6 -15 -5
-32 1 -18 -3 -36 -9 -40 -7 -4 3 -9 23 -10 19 -1 -17 -5 -80 -8 l-115 -6 160
-1 c92 0 145 3 125 7 -21 4 -26 8 -14 9 16 1 23 9 27 34 8 54 6 177 -4 177 -5
0 -9 -45 -9 -100 l0 -100 -40 0 -40 0 0 95 0 95 38 1 c30 0 33 2 17 9 -25 11
-63 12 -68 3z"/>
                    <path d="M898 803 l-156 -3 -7 -73 c-4 -39 -5 -88 -3 -107 l3 -35 95 -6 c114
-8 365 -11 359 -4 -7 6 -163 13 -316 14 l-133 1 1 38 c1 20 3 30 6 21 5 -20
139 -37 156 -20 8 8 -11 11 -66 11 -70 0 -77 2 -77 20 0 18 7 20 83 21 l82 2
-70 8 c-38 4 -78 5 -87 2 -13 -3 -18 1 -18 16 0 20 6 21 80 21 50 0 80 4 80
11 0 7 -28 9 -80 7 -76 -3 -81 -2 -78 17 3 18 12 20 91 23 l87 3 0 -91 c0 -59
4 -90 11 -90 6 0 8 7 5 17 -9 22 5 163 15 163 5 0 9 -35 9 -77 0 -43 5 -85 10
-93 7 -11 10 -12 10 -2 0 11 17 12 86 7 64 -6 85 -5 80 4 -4 7 -39 11 -91 11
-78 0 -83 1 -78 20 5 17 16 20 92 23 l86 3 -87 2 -88 2 0 50 0 50 95 0 94 0 4
-97 3 -98 2 101 2 102 -31 6 c-17 3 -48 5 -68 4 -20 -1 -107 -4 -193 -5z"/>
                    <path d="M1302 785 l-17 -20 24 20 c24 19 24 20 17 1 -7 -19 -6 -19 14 -1 25
23 25 24 0 22 -12 -1 -29 -10 -38 -22z"/>
                    <path d="M1335 752 c-16 -19 -23 -33 -16 -31 16 6 57 57 50 62 -2 2 -17 -12
-34 -31z"/>
                    <path d="M1038 733 c23 -2 61 -2 85 0 23 2 4 4 -43 4 -47 0 -66 -2 -42 -4z" />
                    <path d="M1910 669 c0 -4 10 -9 23 -11 12 -2 23 -9 23 -15 1 -7 3 -16 4 -20 1
-5 2 -11 1 -15 0 -5 4 -8 9 -8 12 0 13 12 1 43 -9 23 -61 45 -61 26z"/>
                    <path d="M1872 643 c2 -10 12 -19 23 -21 18 -3 19 -2 2 8 -9 6 -15 15 -12 21
4 5 1 9 -5 9 -6 0 -10 -8 -8 -17z"/>
                    <path d="M1830 611 c0 -5 4 -21 10 -35 8 -21 14 -24 41 -21 45 6 49 15 7 15
-33 0 -36 2 -33 25 1 16 -3 25 -11 25 -8 0 -14 -4 -14 -9z"/>
                    <path d="M1905 611 c-20 -4 -26 -9 -17 -14 14 -10 52 0 52 14 0 5 -1 8 -2 8
-2 -1 -16 -4 -33 -8z"/>
                    <path d="M2285 610 c-4 -7 -32 -10 -71 -9 -35 1 -67 -1 -71 -4 -3 -4 43 -7
103 -7 60 0 120 -3 134 -6 18 -4 21 -3 11 4 -11 8 -11 10 0 15 8 2 -5 2 -28 0
-26 -4 -49 -1 -57 6 -10 9 -16 9 -21 1z"/>
                    <path d="M669 601 c-2 -6 -33 -16 -69 -22 -36 -6 -69 -16 -75 -21 -11 -10 38
-4 107 13 36 9 56 25 45 35 -3 3 -7 0 -8 -5z"/>
                    <path d="M2410 601 c0 -5 27 -11 60 -13 67 -3 81 6 23 16 -54 8 -83 7 -83 -3z" />
                    <path d="M1998 549 l4 -42 93 5 c82 5 93 3 88 -11 -3 -12 -1 -11 11 3 12 15
23 17 52 13 24 -4 34 -3 28 3 -10 10 -38 12 -156 11 -37 0 -77 4 -90 9 -19 9
-17 10 15 6 22 -3 35 -1 32 5 -4 5 -19 9 -36 9 -19 0 -29 5 -29 15 0 8 -3 15
-8 15 -4 0 -6 -19 -4 -41z"/>
                    <path d="M2305 570 c44 -17 120 -21 157 -8 34 12 30 13 -70 14 -59 1 -98 -2
-87 -6z"/>
                    <path d="M1383 563 c37 -2 96 -2 130 0 34 2 4 3 -68 3 -71 0 -99 -1 -62 -3z" />
                    <path d="M1670 560 c-8 -5 -93 -10 -187 -10 -98 0 -173 -4 -173 -9 0 -9 442
-4 470 5 13 4 13 6 0 14 -20 13 -91 12 -110 0z"/>
                    <path d="M1810 551 c0 -10 7 -21 15 -25 17 -6 21 9 5 19 -6 4 -8 11 -5 16 4 5
1 9 -4 9 -6 0 -11 -9 -11 -19z"/>
                    <path d="M2130 560 c-23 -7 -22 -8 10 -8 19 0 49 4 65 8 29 7 28 8 -10 8 -22
0 -51 -4 -65 -8z"/>
                    <path d="M718 550 c12 -5 22 -16 22 -24 0 -9 14 -15 45 -18 25 -2 45 0 45 4 0
5 -18 8 -40 8 -23 0 -40 5 -40 11 0 7 9 9 23 6 12 -4 29 -2 37 3 12 8 9 10
-10 9 -14 0 -43 2 -65 5 -30 4 -35 3 -17 -4z"/>
                    <path d="M837 553 c-3 -5 -1 -17 3 -28 7 -19 7 -19 14 3 6 17 12 21 28 16 14
-4 18 -2 14 5 -8 12 -52 15 -59 4z"/>
                    <path d="M1940 551 c-15 -4 -20 -10 -13 -14 13 -8 43 1 43 14 0 5 -1 9 -2 8
-2 -1 -14 -4 -28 -8z"/>
                    <path d="M2250 550 c-20 -6 -21 -8 -5 -8 11 0 31 4 45 8 20 6 21 8 5 8 -11 0
-31 -4 -45 -8z"/>
                    <path d="M693 527 c9 -8 17 -20 17 -27 0 -7 -8 -19 -17 -26 -16 -12 -16 -13
-2 -14 28 0 40 23 26 50 -7 13 -19 25 -27 27 -11 3 -11 1 3 -10z"/>
                    <path d="M632 517 c-8 -9 -5 -19 8 -37 11 -14 20 -20 20 -14 0 5 -5 15 -12 22
-8 8 -8 15 2 27 7 8 8 15 2 15 -5 0 -14 -6 -20 -13z"/>
                    <path d="M862 519 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8 3
-12 -1 -9 -9z"/>
                    <path d="M914 521 c5 -8 76 -5 85 4 2 3 -17 5 -43 5 -26 0 -45 -4 -42 -9z" />
                    <path d="M1670 510 c0 -6 4 -9 9 -5 5 3 12 1 16 -5 3 -5 16 -10 28 -10 27 0
11 16 -25 25 -19 5 -28 3 -28 -5z"/>
                    <path d="M1833 505 c-18 -8 -33 -10 -33 -5 0 6 -8 10 -17 10 -15 0 -14 -2 2
-14 11 -8 23 -12 27 -10 4 2 9 0 13 -6 3 -5 14 -10 23 -10 15 1 14 3 -3 17
-19 15 -18 15 15 8 81 -16 110 -18 110 -6 0 6 -18 11 -43 11 -24 0 -47 4 -52
9 -6 6 -22 4 -42 -4z"/>
                    <path d="M2301 510 c-9 -5 -6 -9 10 -13 20 -5 21 -8 8 -24 -13 -16 -12 -17 4
-4 20 16 22 9 6 -22 -11 -20 -10 -21 5 -6 30 29 1 90 -33 69z"/>
                    <path d="M546 503 c-4 -4 -21 -7 -38 -8 -17 0 -33 -5 -35 -11 -2 -7 18 -7 69
0 50 6 66 12 52 17 -25 10 -39 11 -48 2z"/>
                    <path d="M1318 503 c-21 -2 -38 -8 -38 -12 0 -4 -90 -25 -200 -46 -110 -22
-200 -43 -200 -48 0 -5 8 -6 18 -3 9 2 105 21 212 42 107 20 197 41 198 46 5
11 42 10 42 -2 0 -5 13 -7 29 -4 16 3 38 1 50 -5 12 -7 27 -9 34 -4 15 9 -24
21 -76 22 -23 1 -36 5 -32 11 3 6 5 9 3 8 -2 -1 -20 -3 -40 -5z"/>
                    <path d="M1391 505 c9 -8 79 -12 79 -4 0 5 -19 9 -42 9 -23 0 -39 -2 -37 -5z" />
                    <path d="M1588 495 c12 -9 26 -14 31 -11 5 3 14 -1 21 -9 13 -16 30 -20 30 -7
0 12 -33 29 -70 37 -34 7 -35 6 -12 -10z"/>
                    <path d="M2029 484 c-11 -14 -10 -18 6 -30 10 -8 24 -14 29 -14 6 0 3 5 -6 11
-22 12 -24 39 -3 39 9 0 12 -6 8 -17 -4 -11 -3 -14 5 -9 19 11 14 36 -7 36
-10 0 -25 -7 -32 -16z"/>
                    <path d="M2150 491 c0 -6 -8 -8 -17 -4 -10 3 -15 3 -11 -1 4 -5 2 -19 -3 -33
-8 -21 -7 -24 7 -19 8 3 20 6 25 6 5 0 9 5 9 11 0 5 -4 8 -9 4 -5 -3 -13 0
-17 6 -4 8 -3 9 4 5 7 -4 12 -2 12 4 0 6 5 8 10 5 6 -3 10 -2 10 4 0 5 -4 13
-10 16 -5 3 -10 2 -10 -4z"/>
                    <path d="M1970 475 c-16 -9 -19 -14 -9 -15 8 0 24 7 35 15 24 18 7 19 -26 0z" />
                    <path d="M2245 480 c3 -5 12 -7 20 -3 8 3 15 0 15 -7 0 -6 5 -8 11 -4 8 4 7 9
-2 15 -19 12 -52 11 -44 -1z"/>
                    <path d="M1510 470 c0 -5 16 -10 36 -10 21 0 33 4 29 10 -3 6 -19 10 -36 10
-16 0 -29 -4 -29 -10z"/>
                    <path d="M1881 459 c9 -17 13 -19 19 -8 11 16 4 29 -16 29 -12 0 -13 -4 -3
-21z"/>
                    <path d="M2190 473 c0 -6 7 -19 16 -28 14 -13 19 -14 25 -3 12 18 11 28 -2 28
-5 0 -7 -4 -4 -10 11 -17 -7 -11 -21 8 -7 9 -14 12 -14 5z"/>
                    <path d="M1690 450 c18 -11 65 -11 100 0 18 6 5 8 -45 9 -49 0 -65 -2 -55 -9z" />
                    <path d="M1825 450 c-3 -5 3 -10 14 -10 12 0 21 5 21 10 0 6 -6 10 -14 10 -8
0 -18 -4 -21 -10z"/>
                  </g>
                </svg>
                <h2>Cartas de imóvel</h2>
              </div>
              <div className='button' onClick={() => { setType('veículo'); setShowFirstScreen(false); }}>
                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 177.000000"
                  preserveAspectRatio="xMidYMid meet">
                  <g transform="translate(0.000000,177.000000) scale(0.100000,-0.100000)"
                    fill="currentColor" stroke="none">
                    <path d="M1505 1315 c-137 -26 -244 -64 -336 -120 -50 -30 -99 -57 -110 -60
-10 -4 -19 -11 -19 -16 0 -13 4 -12 44 12 19 11 40 18 46 14 6 -4 9 -1 8 6 -2
6 3 14 11 17 9 3 11 0 6 -7 -4 -7 15 -1 42 13 68 35 170 76 188 76 8 0 15 5
15 10 0 14 -1 14 -97 -25 -87 -35 -122 -41 -64 -10 38 19 133 49 216 67 37 8
135 13 258 14 108 1 200 3 202 6 7 7 -172 18 -265 17 -41 -1 -106 -7 -145 -14z
m-309 -130 c-11 -8 -24 -15 -30 -15 -5 0 -1 7 10 15 10 8 24 14 29 14 6 0 1
-6 -9 -14z"/>
                    <path d="M1890 1280 c-41 -11 -79 -25 -84 -30 -13 -13 -11 -13 59 9 130 40
226 36 330 -16 45 -23 238 -154 232 -159 -1 -1 -123 -4 -272 -7 -148 -3 -316
-12 -372 -18 -57 -7 -105 -10 -108 -7 -3 3 -3 11 0 19 5 14 69 18 82 5 10 -10
41 -7 49 5 4 7 0 9 -13 5 -27 -9 -54 21 -32 35 15 10 166 13 156 3 -3 -3 -30
-10 -59 -14 l-53 -8 46 -1 c26 0 53 5 65 14 32 24 0 37 -78 31 -78 -7 -98 -13
-98 -31 0 -15 -38 -31 -47 -21 -17 17 93 96 169 122 48 16 50 15 68 -4 22 -23
92 -39 194 -44 92 -5 54 7 -62 20 -59 7 -104 18 -120 29 l-27 18 63 9 c53 7
72 6 129 -13 37 -12 89 -38 115 -56 26 -19 45 -29 42 -22 -8 21 -96 74 -153
92 -147 47 -375 -35 -443 -161 -15 -27 -31 -47 -35 -46 -16 7 -153 -10 -159
-20 -4 -6 4 -8 22 -5 16 3 57 9 92 12 l62 7 0 -36 c0 -25 -4 -36 -15 -36 -8 0
-34 -6 -57 -14 -24 -8 -56 -17 -72 -20 -15 -4 -26 -9 -23 -12 4 -4 82 13 148
32 14 4 17 -11 21 -109 5 -114 18 -157 47 -157 8 0 62 11 120 24 214 50 292
67 331 72 22 3 53 10 68 14 l27 8 -30 1 c-17 1 -45 -3 -63 -8 -29 -9 -32 -8
-32 12 0 35 11 43 78 57 34 7 62 17 62 21 0 12 -6 11 -68 -5 -50 -14 -53 -13
-47 2 3 9 11 36 18 60 l11 42 48 0 c26 0 48 5 48 10 0 6 9 10 20 10 11 0 20 5
20 10 0 6 -28 10 -65 10 -62 0 -65 1 -65 24 l0 25 153 -2 152 -2 3 -39 c2 -25
0 -36 -7 -31 -6 3 -11 20 -11 36 0 18 -4 28 -10 24 -12 -8 -2 -54 16 -75 21
-25 26 -91 11 -147 -16 -61 -12 -69 9 -17 12 28 13 59 8 150 -6 99 -9 114 -24
114 -10 0 -62 33 -117 73 -189 138 -309 170 -473 127z m280 -235 c0 -14 -6
-25 -12 -25 -67 -1 -332 -27 -407 -40 -76 -14 -75 -14 -87 24 l-10 34 115 7
c64 4 177 11 251 15 74 4 138 8 143 8 4 1 7 -9 7 -23z m0 -45 c0 -5 -15 -10
-32 -9 -31 1 -32 1 -8 9 35 11 40 11 40 0z m89 6 c-12 -10 -79 -16 -79 -6 0 5
19 10 42 10 23 0 39 -2 37 -4z m-211 -13 c-15 -2 -42 -2 -60 0 -18 2 -6 4 27
4 33 0 48 -2 33 -4z m112 -20 c0 -26 -37 -114 -49 -117 -39 -11 -255 -57 -335
-71 -88 -15 -119 -25 -79 -25 28 0 277 47 343 65 72 19 70 20 70 -14 0 -31 -2
-32 -83 -47 -31 -5 -269 -60 -303 -70 -43 -12 -54 17 -54 145 l0 117 38 1 c20
0 80 5 132 10 112 12 320 16 320 6z"/>
                    <path d="M1768 1033 c-21 -2 -38 -7 -38 -11 0 -4 18 -6 40 -4 22 2 40 8 40 13
0 5 -1 8 -2 7 -2 -1 -20 -3 -40 -5z"/>
                    <path d="M1491 1271 c-8 -5 28 -9 94 -9 58 0 120 -4 137 -7 l30 -7 -28 -29
c-19 -20 -31 -26 -38 -19 -17 17 -62 11 -89 -11 -36 -28 -50 -30 -232 -36 -88
-3 -163 -8 -167 -12 -13 -12 168 -12 272 0 84 10 111 17 133 36 30 24 53 29
75 14 10 -6 2 -18 -32 -46 l-47 -38 -237 7 c-246 8 -309 3 -117 -8 355 -20
341 -20 373 0 46 29 153 134 147 144 -10 17 -71 27 -166 29 -52 1 -100 -3
-108 -8z"/>
                    <path d="M2036 1118 c-141 -12 -115 -20 34 -11 74 4 150 7 168 7 17 0 32 4 32
8 0 10 -88 8 -234 -4z"/>
                    <path d="M977 1103 c-5 -4 -41 -8 -80 -9 -117 -3 -287 -65 -345 -127 -57 -60
-81 -269 -33 -285 10 -3 11 0 2 16 -6 11 -10 45 -10 74 1 50 2 51 8 16 17 -83
48 -101 194 -114 73 -6 77 -6 77 13 0 11 12 38 26 59 15 21 29 58 32 81 6 51
35 68 58 34 16 -23 49 -28 60 -10 4 7 0 9 -13 5 -12 -4 -25 -1 -33 9 -14 17
-13 18 68 34 30 6 58 8 64 5 6 -4 13 -3 17 2 3 5 38 17 76 27 119 32 113 31
85 7 -23 -20 -132 -80 -145 -80 -3 0 -3 12 0 28 5 24 4 25 -4 5 -12 -26 -40
-43 -78 -44 l-28 -1 30 -7 c17 -4 31 -3 33 1 2 4 10 4 18 1 8 -3 14 0 14 6 0
6 6 8 14 5 16 -6 131 49 164 79 12 11 22 27 22 34 0 10 -6 12 -22 7 -50 -16
-100 -28 -243 -58 -82 -17 -152 -35 -154 -39 -1 -4 -8 -1 -15 8 -11 15 -112
38 -216 49 -83 8 -29 -8 95 -29 61 -10 113 -23 117 -29 15 -23 7 -64 -22 -109
-16 -26 -30 -57 -30 -67 0 -19 -5 -20 -67 -14 -93 8 -137 20 -159 45 -14 16
-15 22 -5 28 8 6 9 11 3 15 -6 3 -13 0 -15 -6 -3 -7 -6 1 -6 19 -1 17 -5 34
-10 37 -14 9 15 106 41 133 31 33 148 89 236 111 86 23 97 13 15 -12 -72 -23
-140 -53 -148 -66 -9 -15 4 -12 39 9 56 35 189 71 311 85 90 10 106 14 65 15
-30 1 -64 4 -75 7 -11 3 -24 2 -28 -3z"/>
                    <path d="M1150 1073 c-142 -22 -379 -89 -404 -114 -16 -15 9 -10 58 11 106 45
290 85 446 96 47 4 87 8 89 10 6 5 -156 3 -189 -3z"/>
                    <path d="M1410 1058 c-337 -48 -610 -104 -625 -128 -3 -5 13 -3 37 6 43 15
243 57 328 69 180 24 385 56 389 60 6 7 -55 4 -129 -7z"/>
                    <path d="M2218 1043 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
                    <path d="M2331 960 c-26 -29 -72 -139 -71 -170 1 -8 9 10 19 40 35 109 73 155
118 144 41 -11 53 -44 49 -136 -2 -45 -9 -95 -15 -110 -10 -26 -9 -27 9 -15
11 6 16 13 11 15 -6 2 -6 16 0 35 5 18 9 64 9 103 0 62 -3 75 -25 98 -33 35
-69 34 -104 -4z"/>
                    <path d="M2393 934 c24 -13 27 -17 14 -27 -8 -7 -18 -23 -21 -37 -5 -23 -4
-23 6 3 12 28 33 36 41 15 4 -9 6 -8 6 3 1 19 -40 59 -60 59 -8 -1 -1 -8 14
-16z"/>
                    <path d="M2321 899 c-24 -40 -35 -104 -28 -162 l6 -42 -14 30 c-8 17 -14 23
-15 15 0 -8 9 -30 20 -47 14 -23 16 -33 7 -34 -28 -3 -53 4 -69 18 -10 9 -18
11 -18 6 0 -6 12 -18 26 -28 21 -13 38 -15 87 -10 58 6 116 30 104 42 -3 3
-16 -2 -29 -11 -30 -21 -62 -20 -79 2 -7 9 -10 14 -5 10 15 -13 46 12 45 35 0
15 -2 17 -6 7 -9 -21 -30 -40 -37 -33 -11 12 -6 33 8 33 8 0 17 7 20 16 5 14
4 15 -9 4 -8 -7 -15 -9 -15 -4 0 5 -5 2 -10 -6 -7 -11 -9 -2 -9 30 1 25 3 37
6 28 5 -19 33 -25 33 -7 0 5 -4 7 -10 4 -11 -7 -24 38 -17 57 4 9 6 8 6 -3 1
-9 7 -19 15 -22 11 -5 11 -1 -1 24 -10 21 -11 33 -4 46 9 15 11 12 12 -17 l1
-35 8 35 c12 49 -4 60 -29 19z"/>
                    <path d="M1286 897 c-43 -27 -96 -99 -96 -131 0 -15 6 -9 19 19 37 82 121 135
186 119 60 -15 115 -110 115 -198 -1 -23 -7 -68 -15 -101 -8 -32 -12 -61 -10
-63 11 -11 28 61 32 140 5 80 4 92 -21 142 -46 93 -128 122 -210 73z"/>
                    <path d="M1563 905 c-41 -17 -41 -28 -2 -72 29 -33 79 -130 79 -152 0 -6 4
-11 10 -11 19 0 -39 127 -80 173 -30 34 -30 36 -11 46 11 6 27 11 35 11 9 0
16 5 16 10 0 13 -8 12 -47 -5z"/>
                    <path d="M575 880 c-38 -25 -21 -25 25 0 19 11 28 19 20 20 -8 0 -28 -9 -45
-20z"/>
                    <path d="M627 871 c-65 -38 -85 -61 -22 -26 65 37 92 55 80 55 -6 0 -32 -13
-58 -29z"/>
                    <path d="M975 881 c-43 -5 -46 -7 -22 -12 16 -4 47 -1 70 7 44 13 33 15 -48 5z" />
                    <path d="M644 840 c-29 -17 -58 -38 -65 -47 -8 -9 18 3 56 27 83 52 81 50 69
50 -5 0 -32 -14 -60 -30z"/>
                    <path d="M715 824 c-39 -25 -79 -55 -90 -66 -11 -11 16 5 61 35 44 30 87 56
95 59 8 3 12 8 9 11 -3 4 -37 -14 -75 -39z"/>
                    <path d="M2395 840 c-24 -25 -26 -30 -12 -36 9 -3 20 -3 26 0 5 3 12 0 14 -6
3 -8 6 -6 6 5 1 14 -4 16 -26 11 -28 -7 -28 -7 -9 14 10 12 23 19 27 16 5 -3
9 2 9 10 0 21 -2 21 -35 -14z"/>
                    <path d="M1326 849 c-69 -19 -126 -109 -124 -194 l1 -50 7 56 c4 30 12 64 19
75 8 12 11 13 8 3 -7 -21 1 -30 43 -53 29 -16 33 -17 22 -3 -8 9 -22 19 -32
22 -19 6 -34 55 -21 68 5 5 18 -8 30 -29 12 -20 25 -34 28 -30 4 3 -3 19 -14
34 l-20 28 27 32 c29 36 60 41 100 16 14 -8 17 -13 8 -10 -21 6 -37 -13 -53
-66 l-14 -43 0 57 c-1 42 3 58 14 61 11 4 10 4 -2 3 -15 -1 -18 -11 -18 -59 0
-31 4 -58 9 -59 4 -2 17 21 28 50 16 44 22 51 34 41 11 -9 14 -8 14 2 0 19 16
0 39 -46 11 -22 20 -34 21 -27 0 20 -25 65 -52 89 -32 30 -68 41 -102 32z"/>
                    <path d="M764 820 c-21 -16 -44 -30 -50 -30 -6 0 -19 -10 -30 -21 -18 -21 -17
-21 23 2 43 24 113 78 102 79 -3 0 -23 -13 -45 -30z"/>
                    <path d="M638 802 c-16 -10 -28 -21 -28 -25 0 -5 14 1 30 13 17 12 30 23 30
26 0 5 0 5 -32 -14z"/>
                    <path d="M860 786 c0 -22 45 -31 88 -18 22 7 41 8 52 2 17 -9 50 -68 50 -88 0
-14 -61 -22 -190 -23 -112 -2 -113 -2 -45 -10 39 -4 108 -3 155 2 47 5 89 9
94 9 15 0 -16 -31 -47 -46 -16 -9 -27 -18 -24 -21 3 -2 14 0 26 6 11 7 22 7
25 2 4 -5 1 -12 -4 -14 -7 -4 -6 -5 1 -3 6 1 10 5 10 9 0 3 6 18 14 33 11 22
11 32 -1 66 -16 47 -17 55 -5 43 19 -20 51 -118 45 -137 -11 -37 -58 -55 -116
-47 -29 4 -79 8 -111 8 -44 1 -69 7 -103 27 -55 32 -58 26 -4 -10 28 -19 54
-27 92 -28 30 -1 4 -5 -57 -9 -97 -5 -91 -6 47 -4 112 2 168 -1 195 -11 21 -7
57 -13 81 -14 41 0 42 1 42 33 l0 32 21 -35 c22 -36 57 -69 74 -70 6 0 -4 11
-21 25 -41 32 -67 96 -67 168 0 34 -4 52 -9 45 -4 -7 -8 -52 -8 -100 l0 -88
-32 1 c-34 1 -93 16 -70 18 23 2 62 49 62 76 0 27 -52 139 -70 150 -6 4 -7 -1
-4 -12 4 -10 -4 -5 -19 12 -24 29 -26 30 -71 17 -59 -15 -61 -15 -80 4 -14 14
-16 14 -16 0z"/>
                    <path d="M1560 792 c0 -5 3 -12 7 -16 4 -4 17 -33 28 -64 17 -48 24 -57 47
-60 34 -4 309 41 444 73 130 31 155 31 49 0 -44 -13 -71 -23 -60 -23 38 0 140
34 137 46 -1 7 5 12 13 12 8 0 15 4 15 10 0 6 -9 6 -26 -1 -23 -8 -136 -35
-229 -54 -110 -22 -338 -56 -349 -52 -7 3 -23 33 -36 66 -20 56 -40 86 -40 63z"/>
                    <path d="M735 760 c-27 -16 -43 -30 -35 -30 8 1 24 7 34 15 11 8 25 15 30 15
6 0 2 -7 -8 -15 -21 -16 -10 -21 13 -6 7 5 11 13 7 19 -3 6 2 15 11 21 10 6
14 10 8 11 -5 0 -32 -14 -60 -30z"/>
                    <path d="M2397 778 c16 -10 20 -17 13 -28 -7 -10 -10 -11 -10 -2 0 6 -5 12
-11 12 -8 0 -8 -5 1 -15 7 -8 10 -22 6 -30 -3 -8 -1 -15 4 -15 6 0 10 9 10 19
0 11 6 21 13 24 17 6 -5 41 -29 45 -16 2 -16 1 3 -10z"/>
                    <path d="M1401 742 c-17 -15 -33 -37 -36 -49 -3 -21 -3 -21 37 -7 22 8 42 14
43 14 2 0 1 -10 -2 -22 -4 -19 -3 -20 6 -8 6 8 11 21 11 29 0 13 -27 12 -75
-5 -15 -5 -11 2 13 25 17 18 37 29 42 26 6 -3 10 -2 10 4 0 21 -18 19 -49 -7z"/>
                    <path d="M2368 710 c-3 -16 -1 -30 4 -30 4 0 8 14 8 30 0 17 -2 30 -4 30 -2 0
-6 -13 -8 -30z"/>
                    <path d="M2228 725 c-3 -3 -133 -35 -289 -70 -156 -35 -304 -71 -329 -79 -44
-14 -41 -15 107 -14 84 1 150 5 148 9 -3 3 -55 5 -117 3 -108 -4 -111 -4 -68
9 102 31 198 51 244 50 27 0 43 3 36 7 -8 5 8 12 41 19 168 36 249 56 249 63
0 9 -14 11 -22 3z"/>
                    <path d="M1483 670 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z" />
                    <path d="M1945 680 c-38 -10 -61 -18 -50 -19 11 0 52 8 90 18 39 11 61 19 50
19 -11 0 -51 -8 -90 -18z"/>
                    <path d="M510 655 c0 -28 46 -47 102 -43 29 1 56 7 59 11 7 9 62 -3 141 -32
36 -13 79 -21 115 -20 l58 2 -70 8 c-38 5 -94 18 -122 29 -53 20 -230 60 -265
60 -10 0 -18 -7 -18 -15z m125 -15 c24 -6 18 -8 -32 -9 -34 0 -65 4 -68 9 -7
12 54 12 100 0z"/>
                    <path d="M1221 642 l-1 -32 41 20 c23 12 39 24 36 26 -3 3 -19 -2 -35 -10 -30
-15 -31 -15 -35 7 -3 16 -5 13 -6 -11z"/>
                    <path d="M1345 649 c-7 -10 15 -42 45 -65 12 -10 12 -14 1 -28 -20 -24 -8 -30
13 -7 16 18 16 20 -6 42 -13 13 -30 32 -37 41 -12 16 -10 17 33 2 40 -14 45
-18 34 -32 -11 -13 -10 -14 2 -10 32 10 16 41 -30 54 -25 8 -46 14 -47 14 -1
0 -5 -5 -8 -11z"/>
                    <path d="M1760 641 c-41 -8 -66 -17 -55 -18 11 -2 54 5 95 16 91 23 64 24 -40
2z"/>
                    <path d="M2056 641 c5 -5 45 -6 94 -1 l85 7 -94 2 c-57 0 -91 -3 -85 -8z" />
                    <path d="M1279 611 c-13 -18 -29 -29 -36 -27 -14 6 -18 -10 -5 -18 11 -6 78
61 70 70 -4 3 -17 -8 -29 -25z"/>
                    <path d="M1313 578 c-7 -35 -13 -42 -29 -40 -15 3 -16 1 -7 -8 20 -21 40 -4
47 40 9 57 0 63 -11 8z"/>
                    <path d="M1585 605 c-67 -17 -36 -18 40 -1 33 8 49 14 35 14 -14 0 -47 -6 -75
-13z"/>
                    <path d="M1346 576 c5 -27 3 -41 -7 -47 -11 -7 -10 -9 4 -9 13 0 17 8 17 33 0
17 -5 39 -11 47 -7 11 -8 4 -3 -24z"/>
                    <path d="M633 593 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z" />
                    <path d="M627 573 c24 -2 62 -2 86 0 23 2 4 4 -43 4 -47 0 -66 -2 -43 -4z" />
                    <path d="M1418 535 c-63 -58 -117 -58 -171 -2 -24 26 -27 27 -15 7 24 -40 77
-64 122 -56 35 7 117 68 106 79 -3 3 -22 -10 -42 -28z"/>
                    <path d="M1547 563 c-15 -15 -5 -23 26 -22 30 2 30 2 4 6 -15 2 -25 9 -22 14
7 10 1 12 -8 2z"/>
                    <path d="M1483 513 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z" />
                    <path d="M978 493 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z" />
                    <path d="M1188 481 l23 -20 -73 -3 c-40 -2 -5 -4 78 -5 115 -2 157 1 178 12
16 8 45 16 65 16 l36 2 -35 6 c-24 4 -46 0 -70 -12 -42 -21 -140 -17 -191 8
l-34 16 23 -20z"/>
                  </g>
                </svg>
                <h2>Cartas de veículo</h2>
              </div>
            </div>
            <div className='otherbutton' onClick={() => { setType(''); setShowFirstScreen(false); }}>
              <h3>Listar todas</h3>
            </div>
          </>
        }
        {!showFirstScreen &&
          <>

            <div className='cards'>
              <div className='commandbar'>
                <h2>Cotas em destaque:</h2>
                <div className={`search ${showSearch && 'showsearch'}`} onClick={() => {
                  //Set focus on the search input
                  document.querySelector('.search input').focus();
                }}>
                  <h3><FaSearch />Buscar</h3>
                  <input type='text' value={searchTerm ?? ''} onChange={(e) => {
                    //If the value is empty, set it to null
                    if (e.target.value === '') {
                      setSearchTerm(null);
                      setShowSearch(false);
                    }
                    else {
                      setSearchTerm(e.target.value);
                      setShowSearch(true);
                    }
                  }} />
                </div>
                <div className='filterbt' onClick={() => {
                  setShowFilterPopup(true);
                  //Scroll to top and disable scrolling for body
                  window.scrollTo(0, 0);
                  document.body.style.overflow = 'hidden';
                }}>
                  <h3><FaFilter />Filtrar</h3>
                  {filters.length > 0 &&
                    <div className='filters'>
                    </div>
                  }
                </div>
              </div>
              <div className='legenda'>
                <div className='selectionmanagement'>
                  <div onClick={() => selectAll()}>
                    <h4>Selecionar todos</h4>
                  </div>
                  <div onClick={() => deselectAll()}>
                    <h4>Limpar seleção</h4>
                  </div>
                </div>
                <h4>Legenda:</h4>
                <div className='legenditem'>
                  <div className='statusdisponivel'>
                    <FaWhatsapp />
                    <h5>Disponível</h5>
                  </div>
                </div>
                <div className='legenditem'>
                  <div className='statusindisponivel'>
                    <MdOutlineBlock />
                    <h5>Indisponível</h5>
                  </div>
                </div>
              </div>
              {selectedData.length === 0 &&
                <div className='noresults'>
                  <h2>Nenhum resultado encontrado.</h2>
                </div>
              }
              {selectedData.map((carta, index) => {
                return (
                  <div className='card' key={carta.id}>
                    <div className='checkbox'>
                      <input type='checkbox' id={`card${index}`} checked={carta.selected} onChange={(e) => {
                        if (e.target.checked) {
                          carta.selected = true;
                          setSelectedCards([...selectedCards, carta]);
                        }
                        else {
                          setSelectedCards(selectedCards.filter(card => card.id !== carta.id));
                          carta.selected = false;
                        }
                      }} />
                    </div>
                    <img src={carta.administrator.logo} alt='logomarca da administradora' />
                    <div className='id'>
                      {carta.category === 'imóvel' ? <h4>Imóvel</h4> : <h4>Veículo</h4>}
                      <h3>Cód.: #{carta.id}</h3>
                    </div>
                    <div className='id'>
                      <h4>Crédito</h4>
                      <h3>{Number(carta.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                    </div>
                    <div className='id'>
                      <h4>Entrada</h4>
                      <h3>{Number(carta.input).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                    </div>
                    <div className='id'>
                      <h4>Parcelas</h4>
                      <h3>{carta.term} x {Number(carta.installment).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                    </div>
                    <div className='infobutton' onClick={() => {
                      setSelectedCard(carta);
                      setShowInfoPopup(true);
                      //Scroll to top and disable scrolling for body
                      window.scrollTo(0, 0);
                      document.body.style.overflow = 'hidden';
                    }}>
                      <h3><IoInformationCircleOutline /></h3>
                    </div>
                    {carta.status === 'disponível' ?
                      <div className='contactbutton' onClick={() => {
                        //Open link on another tab
                        const text = encodeURIComponent(`Olá, me interesso pela cota contemplada de ${carta.category} código #${carta.id} com valor de crédito de ${Number(carta.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })} administrado pela ${carta.administrator.name}. (via website)`);
                        window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${text}`, '_blank');
                      }}>
                        <h3><FaWhatsapp /></h3>
                      </div>
                      :
                      <div className='unavailablebutton'>
                        <h3><MdOutlineBlock /></h3>
                      </div>
                    }
                  </div>
                )
              })}
            </div>
          </>
        }

        {showFilterPopup &&
          <>
            <div className='popup' onClick={() => {
              setShowFilterPopup(false);
              //Enable scrolling for body
              document.body.style.overflow = 'auto';
            }}>
              <div className='container' onClick={(e) => { e.stopPropagation(); }}>
                <div className='title'>
                  <h2><FaFilter />Filtrar</h2>
                  <button className='closepopup' onClick={() => {
                    setShowFilterPopup(false);
                    //Enable scrolling for body
                    document.body.style.overflow = 'auto';
                  }}><IoClose /></button>
                </div>

                <div className='content'>

                  <div className='filteritem'>
                    <h3>Tipo:</h3>
                    <select value={type} onChange={(e) => setType(e.target.value)}>
                      <option value=''>Todos</option>
                      <option value='imóvel'>Imóvel</option>
                      <option value='veículo'>Veículo</option>
                    </select>
                  </div>

                  <div className='filteritem'>
                    <h3>Crédito a partir de:</h3>
                    <span>R$</span>
                    <input type='number' value={minValue ?? ''} onChange={(e) => {
                      //If the value is empty, set it to null
                      if (e.target.value === '')
                        setMinValue(null);
                      else
                        setMinValue(e.target.value);
                    }} />
                  </div>

                  <div className='filteritem'>
                    <h3>Crédito até:</h3>
                    <span>R$</span>
                    <input type='number' value={maxValue ?? ''} onChange={(e) => {
                      //If the value is empty, set it to null
                      if (e.target.value === '')
                        setMaxValue(null);
                      else
                        setMaxValue(e.target.value);
                    }} />
                  </div>

                  <div className='filteritem'>
                    <h3>Parcela a partir de:</h3>
                    <span>R$</span>
                    <input type='number' value={minInstallment ?? ''} onChange={(e) => {
                      //If the value is empty, set it to null
                      if (e.target.value === '')
                        setMinInstallment(null);
                      else
                        setMinInstallment(e.target.value);
                    }} />
                  </div>

                  <div className='filteritem'>
                    <h3>Parcela até:</h3>
                    <span>R$</span>
                    <input type='number' value={maxInstallment ?? ''} onChange={(e) => {
                      //If the value is empty, set it to null
                      if (e.target.value === '')
                        setMaxInstallment(null);
                      else
                        setMaxInstallment(e.target.value);
                    }} />
                  </div>


                </div>

              </div>

            </div>
          </>
        }

        {showSumPopup &&
          <>
            <div className='popup' onClick={() => {
              setShowSumPopup(false);
              //Enable scrolling for body
              document.body.style.overflow = 'auto';
            }}>
              <div className='container' onClick={(e) => { e.stopPropagation(); }}>
                <div className='title'>
                  <h2><FaCalculator />Resultado da soma das cotas</h2>
                  <button className='closepopup' onClick={() => {
                    setShowSumPopup(false);
                    //Enable scrolling for body
                    document.body.style.overflow = 'auto';
                  }}><IoClose /></button>
                </div>

                <div className='content'>

                  <div className='sumitem'>
                    <h3><span>Crédito:</span> {Number(selectedCards.reduce((acc, card) => acc + card.value, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                  </div>
                  <div className='sumitem'>
                    <h3><span>Entrada:</span> {Number(selectedCards.reduce((acc, card) => acc + card.input, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                  </div>
                  <div className='sumitem'>
                    <h3><span>Parcelamento:</span></h3>
                    {
                      summarizeInstallments(selectedCards).map((installment, index) => {
                        return (
                          <h3 key={index} className='installmentssum'>
                            {(installment.rangeStart !== installment.rangeEnd) ? (installment.rangeStart + ' à ' + installment.rangeEnd) : (installment.rangeStart)}: {Number(installment.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                          </h3>
                        )
                      })
                    }
                  </div>
                  <div className='sumitem'>
                    <h3><span>Transferência:</span> {Number(selectedCards.reduce((acc, card) => acc + card.transfer, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                  </div>
                  <div className='sumitem'>
                    <h3><span>Saldo devedor:</span> {Number(selectedCards.reduce((acc, card) => acc + card.installment * card.term, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                  </div>
                  <div className='sumitem'>
                    <h3><span>Seguro de vida:</span> {Number(selectedCards.reduce((acc, card) => acc + card.insurance, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                  </div>
                  {//Only show fundo comum if there is at least one card with fundo_comum
                    selectedCards.some(card => card.fundo_comum > 0) &&
                    <div className='sumitem'>
                      <h3><span>Fundo comum:</span> {Number(selectedCards.reduce((acc, card) => acc + card.fundo_comum, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                    </div>
                  }
                  {//Only show avaliação if there is at least one card with garantia_ref
                    selectedCards.some(card => card.garantia_ref > 0) &&
                    <div className='sumitem'>
                      <h3><span>Avaliação do imóvel:</span> {Number(selectedCards.reduce((acc, card) => acc + card.garantia_ref, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                    </div>
                  }
                  <div className='sumitem'>
                    <h3><span>Cotas selecionadas:</span></h3>
                    {selectedCards.map((card, index) => {
                      return (
                        <h3 key={index}>Cód.: {card.id} - {card.category}: {Number(card.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>
                      )
                    })}
                  </div>

                  <div className='buttonssharesum'>
                    <div className='sharebt' onClick={() => {
                      //Open link on another tab
                      let text = `*Resultado da soma das cotas contempladas*`;
                      //Adicionar crédito ao texto
                      text = text + `\nCrédito: ${Number(selectedCards.reduce((acc, card) => acc + card.value, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      //Adicionar entrada ao texto
                      text = text + `\nEntrada: ${Number(selectedCards.reduce((acc, card) => acc + card.input, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      //Adicionar parcelamento ao texto
                      text = text + `\nParcelamento:`;
                      summarizeInstallments(selectedCards).forEach(installment => {
                        text = text + `\n${(installment.rangeStart !== installment.rangeEnd) ? (installment.rangeStart + ' à ' + installment.rangeEnd) : (installment.rangeStart)}: ${Number(installment.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      });
                      //Adicionar transferência ao texto
                      text = text + `\nTransferência: ${Number(selectedCards.reduce((acc, card) => acc + card.transfer, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      //Adicionar saldo devedor ao texto
                      text = text + `\nSaldo devedor: ${Number(selectedCards.reduce((acc, card) => acc + card.installment * card.term, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      //Adicionar seguro ao texto
                      text = text + `\nSeguro de vida: ${Number(selectedCards.reduce((acc, card) => acc + card.insurance, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      //Adicionar fundo comum ao texto se houver
                      if (selectedCards.some(card => card.fundo_comum > 0)) {
                        text = text + `\nFundo comum: ${Number(selectedCards.reduce((acc, card) => acc + card.fundo_comum, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      }
                      //Adicionar avaliação ao texto se houver garantia_ref
                      if (selectedCards.some(card => card.garantia_ref > 0)) {
                        text = text + `\nAvaliação do imóvel: ${Number(selectedCards.reduce((acc, card) => acc + card.garantia_ref, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      }
                      //Adicionar cotas ao texto
                      text = text + `\nCotas selecionadas:`;
                      selectedCards.forEach(card => {
                        text = text + `\nCód.: ${card.id} - ${card.category}: ${Number(card.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      });
                      window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`, '_blank');
                    }}>
                      <h3>
                        <IoMdShare />
                        Compartilhar
                      </h3>
                    </div>
                    <div className='negotiatesumbt' onClick={() => {
                      //Open link on another tab
                      let text = `Olá, tenho interesse na seguinte opção:\n\n*Resultado da soma das cotas contempladas*`;
                      //Adicionar crédito ao texto
                      text = text + `\nCrédito: ${Number(selectedCards.reduce((acc, card) => acc + card.value, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      //Adicionar entrada ao texto
                      text = text + `\nEntrada: ${Number(selectedCards.reduce((acc, card) => acc + card.input, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      //Adicionar parcelamento ao texto
                      text = text + `\nParcelamento:`;
                      summarizeInstallments(selectedCards).forEach(installment => {
                        text = text + `\n${(installment.rangeStart !== installment.rangeEnd) ? (installment.rangeStart + ' à ' + installment.rangeEnd) : (installment.rangeStart)}: ${Number(installment.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      });
                      //Adicionar transferência ao texto
                      text = text + `\nTransferência: ${Number(selectedCards.reduce((acc, card) => acc + card.transfer, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      //Adicionar saldo devedor ao texto
                      text = text + `\nSaldo devedor: ${Number(selectedCards.reduce((acc, card) => acc + card.installment * card.term, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      //Adicionar seguro ao texto
                      text = text + `\nSeguro de vida: ${Number(selectedCards.reduce((acc, card) => acc + card.insurance, 0)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      //Adicionar cotas ao texto
                      text = text + `\nCotas selecionadas:`;
                      selectedCards.forEach(card => {
                        text = text + `\nCód.: ${card.id} - ${card.category}: ${Number(card.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                      });
                      //Open link with phone number
                      window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(text)}`, '_blank');
                    }}>
                      <h3>
                        <FaWhatsapp />
                        Negociar
                      </h3>
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </>
        }

        {showInfoPopup &&
          <>
            <div className='popup' onClick={() => {
              setShowInfoPopup(false);
              //Enable scrolling for body
              document.body.style.overflow = 'auto';
            }}>
              <div className='container' onClick={(e) => { e.stopPropagation(); }}>
                <div className='title'>
                  <h2><IoInformationCircleOutline />Detalhes da cota</h2>
                  <button className='closepopup' onClick={() => {
                    setShowInfoPopup(false);
                    //Enable scrolling for body
                    document.body.style.overflow = 'auto';
                  }}><IoClose /></button>
                </div>
                <div className='content'>
                  <div className='logo'>
                    <img src={selectedCard.administrator.logo} alt='logomarca da administradora' />
                  </div>
                  <div className='text'>
                    {selectedCard.id && <h3><span>Cód.:</span> #{selectedCard.id}</h3>}
                    {selectedCard.administrator.name && <h3><span>Administradora:</span> {selectedCard.administrator.name}</h3>}
                    {selectedCard.category && <h3><span>Categoria:</span> {selectedCard.category}</h3>}
                    {selectedCard.value && <h3><span>Crédito:</span> {Number(selectedCard.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>}
                    {selectedCard.term && <h3><span>Parcelas:</span> {selectedCard.term} x {Number(selectedCard.installment).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>}
                    {selectedCard.input && <h3><span>Entrada:</span> {Number(selectedCard.input).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>}
                    {selectedCard.transfer && <h3><span>Transferência:</span> {Number(selectedCard.transfer).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>}
                    {selectedCard.installment && selectedCard.term && <h3><span>Saldo devedor:</span> {Number(selectedCard.term * selectedCard.installment).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>}
                    {selectedCard.insurance && <h3><span>Seguro de vida:</span> {Number(selectedCard.insurance).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>}
                    {selectedCard.fundo_comum && <h3><span>Fundo comum:</span> {Number(selectedCard.fundo_comum).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>}
                    {selectedCard.garantia_ref && <h3><span>Avaliação do imóvel:</span> {Number(selectedCard.garantia_ref).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</h3>}
                    {selectedCard.group && <h3><span>Grupo:</span> {selectedCard.group}</h3>}
                    {selectedCard.obs && <h3><span>Observações:</span> {selectedCard.obs}</h3>}
                    {selectedCard.status && <h3><span>Situação:</span> {selectedCard.status}</h3>}
                  </div>
                  <div className='sharebt' onClick={() => {
                    //Open link on another tab
                    let text = `*Carta contemplada de ${selectedCard.category}*`;
                    //Se tiver código, adicionar ao texto
                    if (selectedCard.id !== undefined)
                      text = text + `\nCód.: #${selectedCard.id}`;
                    //Se tiver grupo, adicionar ao texto
                    if (selectedCard.group)
                      text = text + `\nGrupo: ${selectedCard.group}`;
                    //Se tiver valor, adicionar ao texto
                    if (selectedCard.value)
                      text = text + `\nCrédito: ${Number(selectedCard.value).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                    //Se tiver entrada, adicionar ao texto
                    if (selectedCard.input)
                      text = text + `\nEntrada: ${Number(selectedCard.input).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                    //Se tiver parcelas, adicionar ao texto
                    if (selectedCard.term && selectedCard.installment)
                      text = text + `\nParcelas: ${selectedCard.term} x ${Number(selectedCard.installment).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                    //Se tiver seguro, adicionar ao texto
                    if (selectedCard.insurance)
                      text = text + `\nSeguro de vida: ${Number(selectedCard.insurance).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                    //Se tiver transferência, adicionar ao texto
                    if (selectedCard.transfer)
                      text = text + `\nTransferência: ${Number(selectedCard.transfer).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                    //Se tiver saldo devedor, adicionar ao texto
                    if (selectedCard.installment && selectedCard.term)
                      text = text + `\nSaldo devedor: ${Number(selectedCard.term * selectedCard.installment).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                    //Se tiver fundo comum, adicionar ao texto
                    if (selectedCard.fundo_comum)
                      text = text + `\nFundo comum: ${Number(selectedCard.fundo_comum).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                    //Se tiver avaliação, adicionar ao texto
                    if (selectedCard.garantia_ref)
                      text = text + `\nAvaliação do imóvel: ${Number(selectedCard.garantia_ref).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`;
                    //Se tiver observações, adicionar ao texto
                    if (selectedCard.obs)
                      text = text + `\nObservações: ${selectedCard.obs}`;
                    //Se tiver situação, adicionar ao texto
                    if (selectedCard.status)
                      text = text + `\nSituação: ${selectedCard.status}`;
                    //Se tiver administradora, adicionar ao texto
                    if (selectedCard.administrator.name)
                      text = text + `\nAdministradora: ${selectedCard.administrator.name}`;

                    window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`, '_blank');
                  }}>
                    <IoMdShare />
                    Compartilhar
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
      <footer>
        <div>
          <p>Desenvolvido por <a href='https://pavantech.com.br'>PavanTech</a> em parceria com <a href='https://csiinvestimento.com.br'>CSI Investimento</a>.</p>
        </div>
      </footer>

    </div>
  );
}

export default App;
